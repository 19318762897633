import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Routes, Route } from "react-router-dom";
import Header from "../Header/Header";
import Users from "../Users/Users";
import Barbers from "../Barbers/Barbers";
import NotFound from "../NotFound/NotFound";

const Panel = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.username !== "" && user.status !== "admin" && user !== "") {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <>
      <Header />
      <Routes>
        <Route
          path={"/"}
          element={<Barbers onSuccess={() => console.log("Success")} />}
        />
        <Route
          path={`barbers`}
          element={<Barbers onSuccess={() => console.log("Success")} />}
        />
        <Route
          path={`gebruikers`}
          element={<Users onSuccess={() => console.log("Success")} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Panel;
