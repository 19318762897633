import React, { useState } from 'react';
import {FormGroup, Label, Input, Button} from 'reactstrap';

function BarberModal(props) {
    const [value, setValue] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [pin, setPin] = useState('');
    const [status, setStatus] = useState('superUser');

    return (
        <div>
            <FormGroup>
                <Label for="newBarber">Naam:</Label>
                <Input type="text" name="name" id="new-user-name" placeholder="Naam" value={value} onChange={(e) => setValue(e.target.value)}/>
            </FormGroup>
            
            <FormGroup>
                <Label for="newBarber">E-mail:</Label>
                <Input type="email" name="email" id="new-user-email" placeholder="example@menssalon.nl" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </FormGroup>
            
            <FormGroup>
                <Label for="newBarber">Gebruiksnaam:</Label>
                <Input type="text" name="username" id="new-user-username" placeholder="Gebruiksnaam" value={username} onChange={(e) => setUsername(e.target.value)}/>
            </FormGroup>
            
            <FormGroup>
                <Label for="newBarber">Wachtwoord:</Label>
                <Input type="password" name="password" id="new-user-password" placeholder="Wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </FormGroup>
            
            <FormGroup>
                <Label for="newBarber">Herhaal wachtwoord:</Label>
                <Input type="password" name="repeat-password" id="new-user-repeat-password" placeholder="Herhaal wachtwoord" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}/>
            </FormGroup>

            <FormGroup>
                <Label for="newBarber">Pin:</Label>
                <Input type="number" name="pin" id="new-user-pin" placeholder="Pin" value={pin} onChange={(e) => pin.length < 4 ? setPin(e.target.value) : null}/>
            </FormGroup>

            <FormGroup>
                <Label for="newBarber">Rol:</Label>
                <Input type="select" name="select" id="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="admin">Admin</option>
                        <option value="superUser">Super user</option>
                        <option value="user">User</option>
                </Input>
            </FormGroup>
            <Button color="primary" onClick={() => props.onAdd({name: value, email: email, userName: username, password: password, repeatPassword: repeatPassword, pin: pin.toString(), status: status})}>Toevoegen</Button>
            <Button outline color="secondary" onClick={() => props.onCancle()}>Annuleren</Button>
        </div>
    )
}

export default BarberModal
