import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar
      className="navbar navbar-expand-lg navbar-light bg-dark"
      expand="md"
    >
      <Link to="/dashboard" className="navbar-brand text-white">
        Hairsalon Mehdi
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <Link to="/dashboard" className="nav-link text-white">
              Dashboard
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/panel/barbers" className="nav-link text-white">
              Barbers
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/panel/gebruikers" className="nav-link text-white">
              Gebruikers
            </Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;
