import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";
import { postAppointment } from "../../api";
import moment from "moment";

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const Appointment = ({
  barber,
  startDate,
  calculateEndTime,
  closeModal,
  showQRModal,
  fetchBarbers,
}) => {
  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [value, setValue] = useState("2");
  const [longEndDate, setLongEndDate] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const endDate = calculateEndTime(startDate, value);
    setEndDate(endDate);
    setLongEndDate(new Date(endDate).toLocaleDateString("nl-NL", options));
  }, [startDate, value, calculateEndTime]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDurationChange = (event) => {
    const newEndDate = calculateEndTime(startDate, event.target.value);
    setEndDate(newEndDate);
    setValue(event.target.value);
    setLongEndDate(new Date(newEndDate).toLocaleDateString("nl-NL", options));
  };

  const handleKeyUp = (keyCode) => {
    if (keyCode === 13) {
      document.activeElement.blur();
    }
  };

  const addNewEvent = async () => {
    try {
      setIsUploading(true);
      const body = {
        barberId: barber.id,
        startDateTime: moment(startDate).format(),
        endDateTime: moment(endDate).format(),
        name: name,
        classes: "color-1",
      };
      const qrResponse = {
        barberName: barber.name,
        startDateTime: moment(startDate).format("YYYYMMDD[T]HHmmss"),
        endDateTime: moment(endDate).format("YYYYMMDD[T]HHmmss"),
        customerName: name,
      };

      if (endDate && name) {
        const json = await postAppointment(body);
        if (json.success) {
          closeModal();
          showQRModal(qrResponse);
          fetchBarbers();
        } else {
          alert("Afspraak overschrijft een ander afspraak.");
        }
      } else {
        alert("Voer uw naam in");
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <br />
      <label>Naam:</label>
      <Input
        value={name}
        onChange={handleNameChange}
        autoComplete="off"
        id="name"
        type="text"
        label="Naam"
        placeholder="Naam"
        onKeyUp={(e) => handleKeyUp(e.keyCode)}
      />
      <br />
      <label>Duur:</label>
      <Input
        type="select"
        name="select"
        id="duration"
        value={value}
        onChange={handleDurationChange}
      >
        <option value="1">15 minuten</option>
        <option value="2">30 minuten</option>
        <option value="3">45 minuten</option>
        <option value="4">1 uur</option>
      </Input>
      <br />
      <label>Van:</label>
      <p>{new Date(startDate).toLocaleDateString("nl-NL", options)}</p>
      <label>Tot:</label>
      <p>{longEndDate}</p>
      <br />
      <Button disabled={isUploading} onClick={addNewEvent}>
        Maak afspraak
      </Button>
    </div>
  );
};

Appointment.propTypes = {
  barber: PropTypes.object.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  calculateEndTime: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  showQRModal: PropTypes.func.isRequired,
  fetchBarbers: PropTypes.func.isRequired,
};

export default Appointment;
