import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  getBarbersWithoutAppointments,
  postBarber,
  deleteBarber,
} from "../../api";
import { Table, Button, Row, Col } from "reactstrap";
import "./Barbers.scss";
import BarberModal from "../BarberModal/BarberModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Barbers = () => {
  const [barbers, setBarbers] = useState([]);
  const [barber, setBarber] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchBarbers = async () => {
      const barbers = await getBarbersWithoutAppointments();
      setBarbers(barbers);
    };
    fetchBarbers();
  }, []);

  const saveBarber = async (body) => {
    const response = await postBarber(body);
    if (response.success) {
      const newBarber = response.message;
      setBarbers((prevBarbers) => [...prevBarbers, newBarber]);
      setModalIsOpen(false);
    }
  };

  const selectBarber = (barber) => {
    setBarber(barber);
    setDeleteModalIsOpen(true);
  };

  const deleteBarberHandler = async (barber) => {
    const response = await deleteBarber(barber.id);
    if (response.success === false) {
      alert(response.message);
    } else {
      setBarbers((prevBarbers) =>
        prevBarbers.filter((b) => b.id !== response.message.id)
      );
      setDeleteModalIsOpen(false);
    }
  };

  return (
    <div className="Barbers">
      <div className="flex-items py-2 px-2">
        <h2 className="">Barbers</h2>
        <Button color="success" size="sm" onClick={() => setModalIsOpen(true)}>
          Barber toevoegen
        </Button>
      </div>
      <div className="px-2">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Barber</th>
              <th>Opties</th>
            </tr>
          </thead>
          <tbody>
            {barbers.map((barber, i) => (
              <tr key={i + 1}>
                <th scope="row">{i + 1}</th>
                <td>{barber.name}</td>
                <td>
                  {barbers.length > 1 ? (
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => selectBarber(barber)}
                    >
                      Verwijderen
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Delete Event Modal"
      >
        <BarberModal
          onCancle={() => setModalIsOpen(false)}
          onAdd={(body) => saveBarber(body)}
        />
      </Modal>
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Delete Barber Modal"
      >
        <div className="modal-content">
          Druk op verwijderen om barber {barber.name} wilt verwijderen.
          <Row className="text-align-center">
            <Col className="col">
              <Button
                className="btn-danger"
                onClick={() => deleteBarberHandler(barber)}
              >
                Verwijderen
              </Button>
            </Col>
            <Col className="col">
              <Button
                className="btn-primary"
                onClick={() => setDeleteModalIsOpen(false)}
              >
                Annuleren
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default Barbers;
