import React, { useState, useEffect, useCallback } from "react";
import Appointment from "../Appointment/Appointment";
import { Row, Col, Button } from "reactstrap";
import ReactAgenda from "../DashboardPackage/reactAgenda";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import moment from "moment";
import Slider from "react-slick";
import {
  getBarbersWithAppointments,
  deleteAppointment,
  updateAppointment,
} from "../../api";
import HUD from "../HUD/HUD";
import PinModal from "../PinModal/PinModal";
import QRModel from "../QRModel/QRModel";

require("moment/locale/nl.js");

const colors = {
  "color-1": "rgba(200,0,0,0) 0%",
  "color-2": "rgba(200,0,0,0) 0%",
  "color-3": "rgba(200,0,0,0) 0%",
};

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const now = new Date();

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [barbers, setBarbers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [cellHeight] = useState(40);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [qrModalIsOpen, setQrModalIsOpen] = useState(false);
  const [qrData, setQrData] = useState({});
  const [rowsPerHour] = useState(4);
  const [numberOfDays] = useState(1);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [barber, setBarber] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [, setSelectedEndDate] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const [pinModalHandle, setPinModalHandle] = useState("");

  const fetchBarbers = useCallback(async () => {
    const encodedDate = encodeURIComponent(startDate.toISOString());
    let responseJSON = await getBarbersWithAppointments(encodedDate);
    if (Array.isArray(responseJSON)) {
      responseJSON.forEach((barber) =>
        barber.appointments.forEach((appointment) => {
          appointment.startDateTime = moment(
            appointment.startDateTime
          ).toDate();
          appointment.endDateTime = moment(appointment.endDateTime).toDate();
          appointment.acceptStatus = {
            response: true,
          };
        })
      );
      setBarbers(responseJSON);
    } else {
      setBarbers([]);
    }
  }, [startDate]);

  useEffect(() => {
    fetchBarbers();
  }, [fetchBarbers]);

  useEffect(() => {
    if (
      !user?.error &&
      typeof user?.username === "string" &&
      user?.username.length > 0
    ) {
      fetchBarbers();
    }
  }, [user, fetchBarbers]);

  useEffect(() => {
    scrollMultiple();
    scrollToCurrentTime();
  }, [barbers]);

  const scrollMultiple = () => {
    const subCatContainer = document.getElementsByClassName(
      "agenda__table --body"
    );

    if (subCatContainer.length > 0) {
      for (let index = 0; index < subCatContainer.length; index++) {
        if (!subCatContainer[index].onscroll) {
          subCatContainer[index].onscroll = (e) => {
            for (let i = 0; i < subCatContainer.length; i++) {
              if (e.target !== subCatContainer[i]) {
                subCatContainer[i].scrollTop = e.target.scrollTop;
              }
            }
          };
        }
      }
    }
  };

  const scrollToCurrentTime = () => {
    document.querySelector(".--time-now")?.scrollIntoView();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleCellSelection = (barber, item) => {
    if (selected && selected[0] === item) {
      return _openModal(item);
    }
    setBarber(barber);
    setSelected([item]);
  };

  const _openModal = (time) => {
    let endTime = moment(time).toDate();
    endTime.setMinutes(endTime.getMinutes() + 30);
    setModalIsOpen(true);
    setSelectedDate(moment(time).toDate());
    setSelectedEndDate(endTime);
    setSelected([time]);
  };

  const handleRangeSelection = (startDate, endDate) => {
    setStartDate(startDate);
    fetchBarbers();
  };

  const onItemRemove = (items, item) => {
    setDeleteModalIsOpen(true);
    setEditModalIsOpen(false);
  };

  const deleteAppointMent = () => {
    setShowPinModal(true);
    setPinModalHandle("delete");
  };

  const changeAppointMent = (item) => {
    setShowPinModal(true);
    setPinModalHandle("edit");
    setNameValue(nameValue);
  };

  const renderPincode = () => {
    switch (pinModalHandle) {
      case "delete":
        return (
          <PinModal
            onSuccess={async (pin) => handleDelete(pin)}
            closeModal={() => {
              setDeleteModalIsOpen(false);
              setShowPinModal(false);
            }}
          />
        );
      case "edit":
        return (
          <PinModal
            onSuccess={async (pin) => handleEdit(pin)}
            closeModal={() => {
              setDeleteModalIsOpen(false);
              setShowPinModal(false);
            }}
          />
        );
      default:
        break;
    }
  };

  const handleDelete = async (pin) => {
    setDeleteModalIsOpen(false);
    setShowPinModal(false);
    await deleteAppointment(selected, pin);
    fetchBarbers();
  };

  const handleEdit = async (pin) => {
    const body = {
      name: nameValue,
      pin: pin,
    };
    setEditModalIsOpen(false);
    setShowPinModal(false);
    await updateAppointment(selected, body);
    fetchBarbers();
  };

  const showQRModal = (qrData) => {
    setQrModalIsOpen(true);
    setQrData(qrData);
  };

  const closeQrModal = () => {
    setQrModalIsOpen(false);
    setQrData({});
  };

  const setToday = () => {
    const today = new Date();
    setStartDate(today);
    fetchBarbers();
  };

  const addDay = () => {
    const addWeek = moment(startDate).add(1, "days");
    setStartDate(addWeek.toDate());
    fetchBarbers();
  };

  const subtractDay = () => {
    const removeWeek = moment(startDate).subtract(1, "days");
    setStartDate(removeWeek.toDate());
    fetchBarbers();
  };

  const addWeek = () => {
    const addWeek = moment(startDate).add(7, "days");
    setStartDate(addWeek.toDate());
    fetchBarbers();
  };

  const subtractWeek = () => {
    const removeWeek = moment(startDate).subtract(7, "days");
    setStartDate(removeWeek.toDate());
    fetchBarbers();
  };

  const calculateEndTime = (startTime, selection) => {
    let endDate = moment(selected[0]).toDate();
    switch (selection) {
      case "1":
        endDate.setMinutes(endDate.getMinutes() + 15);
        break;
      case "2":
        endDate.setMinutes(endDate.getMinutes() + 30);
        break;
      case "3":
        endDate.setMinutes(endDate.getMinutes() + 45);
        break;
      case "4":
        endDate.setMinutes(endDate.getMinutes() + 60);
        break;
      case "5":
        endDate.setMinutes(endDate.getMinutes() + 75);
        break;
      case "6":
        endDate.setMinutes(endDate.getMinutes() + 90);
        break;
      default:
        endDate.setMinutes(endDate.getMinutes() + 30);
        break;
    }
    return endDate;
  };

  const handleItemEdit = (item) => {
    if (selected && selected.id === item.id) {
      return _onItemEdit(item);
    }
    setSelected(item);
  };

  const _onItemEdit = (item) => {
    setNameValue(item.name);
    setEditModalIsOpen(true);
  };

  const handleDrag = (item) => {
    console.log("handleRangeSelection", item);
  };

  const renderAppointmentModal = () => (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Event Modal"
    >
      <div className="modal-content">
        Afspraak bij: {barber.name}
        <Appointment
          barber={barber}
          showQRModal={showQRModal}
          fetchBarbers={fetchBarbers}
          closeModal={closeModal}
          startDate={selectedDate}
          calculateEndTime={calculateEndTime}
        />
      </div>
    </Modal>
  );

  const renderQRModal = () => (
    <Modal
      isOpen={qrModalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={closeQrModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="QR Modal"
    >
      <div className="modal-content">
        <QRModel qrData={qrData} onClose={closeQrModal}></QRModel>
      </div>
    </Modal>
  );

  const renderAppointmentDeleteModal = () => (
    <Modal
      isOpen={deleteModalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={closeDeleteModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Delete Event Modal"
    >
      <div className="modal-content">
        Druk op verwijderen als u de afspraak van {selected.name} wilt
        verwijderen.
        <Row className="text-align-center">
          <Col className="col">
            <Button
              className="btn-danger"
              onClick={() => deleteAppointMent(selected)}
            >
              Verwijderen
            </Button>
          </Col>
          <Col className="col">
            <Button className="btn-primary" onClick={closeDeleteModal}>
              Annuleren
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );

  const renderAppointmentEditModal = () => (
    <Modal
      isOpen={editModalIsOpen}
      onAfterOpen={() => {}}
      onRequestClose={closeEditModal}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Edit Event Modal"
    >
      <div className="modal-content">
        <input value={nameValue} onChange={handleNameChange}></input>
        {selected.id ? (
          <div>
            <span>Van</span>
            <p>{selected.startDateTime.toLocaleDateString("nl-NL", options)}</p>
            <span>Tot</span>
            <p>
              {selected.endDateTime.toLocaleDateString("nl-NL", options)}
            </p>{" "}
          </div>
        ) : (
          <p>Ongeldige datum</p>
        )}
        <Row>
          <Col>
            <Button color="danger" outline onClick={onItemRemove}>
              Verwijderen
            </Button>
          </Col>
          <Col>
            <Button color="primary" onClick={changeAppointMent}>
              Aanpassen
            </Button>
          </Col>
          <Col>
            <Button className="btn btn-primary" onClick={closeEditModal}>
              Annuleren
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );

  const barbersToShow = barbers.length > 3 ? 3 : barbers.length;
  const showArrows = barbers.length > barbersToShow;

  const settings = {
    dots: false,
    slidesToShow: barbersToShow,
    slidesToScroll: 1,
    infinite: false,
    arrows: showArrows,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="dashboard">
      <Row className="remove-margin barber-background">
        <Col md={12} className="dashboard-agenda">
          <Slider {...settings}>
            {barbers.map((barber, i) => (
              <Col md={12} key={i} className="transparent no-padding">
                <div className="barber-name-box">
                  <p key={`${i},${i}`} className="text-align-center">
                    {barber.name}
                  </p>
                </div>
                <ReactAgenda
                  key={i + i + i}
                  minDate={new Date(now.getFullYear(), now.getMonth() - 3)}
                  maxDate={new Date(now.getFullYear(), now.getMonth() + 3)}
                  disablePrevButton={false}
                  startDate={startDate}
                  startAtTime={7}
                  endAtTime={22}
                  cellHeight={cellHeight}
                  locale={"nl"}
                  helper={false}
                  items={barber.appointments ?? []}
                  numberOfDays={numberOfDays}
                  rowsPerHour={rowsPerHour}
                  itemColors={colors}
                  autoScale={false}
                  fixedHeader={true}
                  onItemRemove={onItemRemove}
                  onItemEdit={handleItemEdit}
                  onRangeSelection={handleDrag}
                  onCellSelect={(item) => handleCellSelection(barber, item)}
                  onDateRangeChange={handleRangeSelection}
                />
              </Col>
            ))}
          </Slider>
        </Col>
        {renderAppointmentModal()}
        {renderQRModal()}
        {renderAppointmentDeleteModal()}
        {renderAppointmentEditModal()}
        {showPinModal && renderPincode()}
      </Row>
      <HUD
        currentDate={startDate}
        setToday={setToday}
        addWeek={addWeek}
        addDay={addDay}
        subtractWeek={subtractWeek}
        subtractDay={subtractDay}
      />
    </div>
  );
};

export default Dashboard;
