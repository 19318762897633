import React from "react";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { combineReducers } from "redux";
import { thunk } from "redux-thunk";

import { createRoot } from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./actions/User";

const allReducers = combineReducers({
  user: userReducer,
});

const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
