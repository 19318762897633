export const API_URI = process.env.REACT_APP_API_URL || "http://localhost:3001";

export async function getBarbersWithoutAppointments() {
  const response = await fetch(`${API_URI}/barbers`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  const responseJSON = await response.json();
  return responseJSON;
}

export async function getBarbersWithAppointments(startDate) {
  const response = await fetch(
    `${API_URI}/barbers/appointments?date=${startDate}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );
  const responseJSON = await response.json();
  return responseJSON;
}

export async function postBarber(body) {
  const response = await fetch(`${API_URI}/barbers`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });
  const json = await response.json();
  return json;
}

export async function deleteBarber(id) {
  const response = await fetch(`${API_URI}/barbers/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  });
  const json = await response.json();
  return json;
}

export async function postAppointment(body) {
  const response = await fetch(`${API_URI}/appointments`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });
  const json = await response.json();
  return json;
}

export async function deleteAppointment(item, pin) {
  const response = await fetch(`${API_URI}/appointments/${item.id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const responseJSON = await response.json();
  return responseJSON;
}

export async function updateAppointment(item, body) {
  body.id = item.id;
  const response = await fetch(`${API_URI}/appointments`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });

  const responseJSON = await response.json();
  return responseJSON;
}

export async function getUsers() {
  const response = await fetch(`${API_URI}/users`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  const responseJSON = await response.json();
  return responseJSON;
}

export async function postUser(body) {
  const response = await fetch(`${API_URI}/users/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  });
  const json = await response.json();
  return json;
}

export async function deleteUser(id) {
  const response = await fetch(`${API_URI}/users/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const responseJSON = await response.json();
  return responseJSON;
}
