import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URI } from "../api";

export const getUser = createAsyncThunk(
  "user/getUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URI}/users/login`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const result = await response.json();
      if (!response.ok) {
        return rejectWithValue(result);
      }
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setUser = createAsyncThunk(
  "user/setUser",
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URI}/users/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const result = await response.json();
      if (!response.ok) {
        return rejectWithValue(result);
      }
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  username: "",
  name: "",
  email: "",
  pin: "0000",
  status: "",
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.username = initialState.username;
      state.name = initialState.name;
      state.email = initialState.email;
      state.pin = initialState.pin;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.username = action.payload.userName;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.pin = action.payload.pin;
        state.status = action.payload.status;
        state.error = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.username = action.payload.user?.userName ?? "";
        state.name = action.payload.user?.name ?? "";
        state.email = action.payload.user?.email ?? "";
        state.status = action.payload.user?.status ?? "";
        state.pin = action.payload.user?.pin ?? "0000";
        state.error = null;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;
