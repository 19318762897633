import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../actions/User";
import { Button, FormGroup, Label, Input } from "reactstrap";
import "./Login.scss";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.username !== "") {
      if (user?.error?.message) {
        alert(user?.error?.message);
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  const handleSubmit = async () => {
    dispatch(setUser({ username, password }));
  };

  const handleKeyUp = (keyCode) => {
    if (keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className="Login">
      <h1>Mehdi's Menssalon</h1>
      <FormGroup controlid="email">
        <Label>Gebruiksnaam</Label>
        <Input
          autoFocus
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </FormGroup>
      <FormGroup controlid="password">
        <Label>Wachtwoord</Label>
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => handleKeyUp(e.keyCode)}
          type="password"
        />
      </FormGroup>
      <Button onClick={handleSubmit}>Login</Button>
    </div>
  );
};

Login.propTypes = {
  host: PropTypes.string,
};

export default Login;
