import React, { Component } from 'react'
import { Row, Col } from 'reactstrap';
import './HUD.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

class HUD extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <div className="HUD">
                    <Row className="text-align-center remove-margin">
                        <Col xs="2" onClick={() => this.props.subtractWeek()} className="substarct-week hud-button">
                            <div className="hud-nav-buttons">
                                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                            </div>
                        </Col>
                        <Col xs="2" onClick={() => this.props.subtractDay()} className="substarct-day hud-button">
                            <div className="hud-nav-buttons">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                        </Col>

                        <Col xs="4" onClick={() => this.props.setToday()} className="current-date hud-button">
                            {this.props.currentDate.toLocaleDateString("nl-NL", options).toUpperCase()}
                        </Col>

                        <Col xs="2" onClick={() => this.props.addDay()} className="add-day hud-button">
                            <div className="hud-nav-buttons">
                                <FontAwesomeIcon icon={faChevronRight} /> 
                            </div>                       
                        </Col>
                        <Col xs="2" onClick={() => this.props.addWeek()} className="add-week hud-button">
                            <div className="hud-nav-buttons">
                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                            </div>
                        </Col>
                    </Row>
            </div>
        )
    }
}

export default HUD