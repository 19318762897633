import React, { useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";

function BarberModal(props) {
  const [value, setValue] = useState("");

  return (
    <div>
      <FormGroup>
        <Label for="newBarber">Barbernaam:</Label>
        <Input
          type="text"
          name="barber"
          id="new-barber"
          placeholder="Naam"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </FormGroup>
      <Button color="primary" onClick={() => props.onAdd({ name: value })}>
        Toevoegen
      </Button>
      <Button outline color="secondary" onClick={() => props.onCancle()}>
        Annuleren
      </Button>
    </div>
  );
}

export default BarberModal;
