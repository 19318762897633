import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader-view">
      <div className="loading">Loading&#8230;</div>
    </div>
  );
};

export default Loader;
